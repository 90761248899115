import React, { useState, useEffect } from "react";
import MaskedInput from "antd-mask-input";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Button, Form, Input, Icon, Checkbox, Select,notification } from "antd";
import { connect } from "react-redux";
import Loader from "../assets/images/loader.svg";
import { userSignIn } from "../appRedux/actions/Auth";
import {
  fetchStart,
  fetchSuccess,
  fetchError,
  showMessage
} from "../appRedux/actions/Common";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import Traductor from "../util/Traductor";
import Error404 from "../components/ourComponents/Error404";
const { Option } = Select;
const COMPANY_QUERY = gql`
  query Company($slug: String!) {
    companies(slug: $slug) {
      id
      slug
      name
      cuit
      address
      phone
      email
      logo
      logoBK
      header
      footer,
      admin_email
    }
  }
`;
const LOGIN_MUTATION = gql`
  mutation LogIn(
    $cuit: String!
    $password: String!
    $company: String!
    $clientId: String
  ) {
    login(
      cuit: $cuit
      password: $password
      company: $company
      clientId: $clientId
    ) {
      token
      user {
        CODIGOCLIENTE
        RAZONSOCIAL
        CONDEBITOAUTOMATICO
      }
      users {
        CODIGOCLIENTE
        RAZONSOCIAL
      }
    }
  }
`;
const FormItem = Form.Item;
const companyMail = "mailto:administracion@flexxus.com.ar";
const SignIn = props => {
  const [customers, setCustomers] = useState([]);
  let { company } = props.match.params;
  const { loading, error, data } = useQuery(COMPANY_QUERY, {
    variables: { slug: `${company}` }
  });

  const [login, dataLogin] = useMutation(LOGIN_MUTATION, {
    fetchPolicy: "no-cache"
  });

  const { getFieldDecorator, getFieldsValue } = props.form;
  useEffect(() => {
    if (props.token !== null) {
      props.history.push("/");
    }
  }, [props.token]);

  if (data && !data.companies[0]) {
    return <Error404 url={props.match.url} />;
  }
  if(error){
    notification["error"]({
      message: 'Ocurrio un error inesperado',
      description:
        `ERROR: ${error.message}`
    });
  }

  const [dataCompanies] =  data?.companies || [];

  const handleSubmit = e => {
    e.preventDefault();
    let from = props.history.location.state
      ? props.history.location.state.from.pathname
      : props.history.location.pathname;
    props.form.validateFields(async (err, values) => {
      if (!err) {
        props.fetchStart();
        let cuit = values.cuit;
        try {
          let { company } = props.match.params;
          const { data } = await login({
            variables: {
              cuit: values.cuit,
              password: values.password,
              clientId: values.clientId,
              company,
            }
          });
          if (data.login.users) {
            setCustomers(data.login.users);
            props.showMessage(
              "Se ha encontrado que usted posee multiples cuentas, por favor seleccione una"
            );
            return;
          }

          props.userSignIn({ ...data.login, cuit, company, dataCompanies });
          props.fetchSuccess();
          if (from.indexOf("signin") === -1) {
            props.history.push(from);
          }
        } catch (err) {
          err.graphQLErrors.forEach(({ message }, i) => {
            props.fetchError(message);
          });

          if (err.networkError) {
            props.fetchError(
              "Tenemos inconvenientes con nuestros servidores, por favor intente de nuevo más tarde"
            );
          }
        }
      }
    });
  };


  const onChangeCuit = (key, value) => {
    const { getFieldsValue } = props.form;
    if (customers.length && getFieldsValue()["cuit"] !== key.target.value) {
      setCustomers([]);
    }
  };

  let { cuit, password } = getFieldsValue();
  if (loading) {
    return (
      <img src={Loader} loading={loading} className="loader" alt="loading" />
    );
  }
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-login-content">
            <div className="imgLoginCont">
              <img
                loading={loading}
                src={
                  data? data.companies[0].logoBK :null
                }
                alt="logo"
              ></img>
            </div>
            <Form
              style={{ padding: "1.5rem 1.3rem 1.3rem " }}
              onSubmit={handleSubmit}
              className="gx-signin-form gx-form-row0"
            >
              <FormItem>
                {getFieldDecorator("cuit", {
                  rules: [
                    {
                      required: true,
                      message: <IntlMessages id="error.invalidcuit" />
                    }
                  ],
                  onChange: onChangeCuit
                })(
                  <MaskedInput
                    prefix={<Icon type="user" />}
                    mask="11-11111111-1"
                    placeholder="99-99999999-5"
                  />
                )}
              </FormItem>

              <FormItem>
                {getFieldDecorator("password")(
                  <Input.Password
                    prefix={<i className="icon icon-forgot-password" />}
                    type="password"
                    placeholder={Traductor(
                      props.locale,
                      "appModule.PasswordRequired"
                    )}
                  />
                )}
              </FormItem>

              {customers.length > 1 && (
                <>
                  <FormItem>
                    {getFieldDecorator("clientId", {
                      rules: [
                        { required: true, message: "Selecciona tu cuenta" }
                      ]
                    })(
                      <Select placeholder="Cuenta a la quiere ingresar">
                        {customers.map(cus => (
                          <Option value={cus.CODIGOCLIENTE}>
                            {cus.RAZONSOCIAL}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </>
              )}

              <FormItem>
                {getFieldDecorator("remember", {
                  valuePropName: "checked",
                  initialValue: true
                })(
                  <Checkbox>
                    <IntlMessages id="app.userAuth.rememberMe" />
                  </Checkbox>
                )}
              </FormItem>
              <FormItem>
                <Button
                  className="buttonEnabled loginBtn gx-mb-0"
                  type="primary"
                  disabled={
                    !cuit || !password || password === "" ? true : false
                  }
                  block
                  htmlType="submit"
                >
                  <IntlMessages id="app.userAuth.login" />
                </Button>
              </FormItem>

              <span className="gx-text-light gx-fs-sm">
                Si no posees una cuenta, contáctate con
                <a href={`mailto:${data ? data.companies[0].email : companyMail}`}>
                  {" "}
                  nosotros.
                </a>
              </span>
            </Form>
          </div>
          <InfoView />
        </div>
      </div>
    </div>
  );
};

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({ auth, settings }) => {
  const { token } = auth;
  const { locale } = settings.locale;

  return { token, locale };
};

export default connect(mapStateToProps, {
  userSignIn,
  fetchStart,
  fetchSuccess,
  fetchError,
  showMessage
})(WrappedNormalLoginForm);
