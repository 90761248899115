import React, { Component } from "react";
import { connect } from "react-redux";
import { Avatar, Popover, Divider } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { userSignOut } from "appRedux/actions/Auth";
class UserInfo extends Component {
  render() {
    const { cuit, RAZONSOCIAL } = this.props.authUser;
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <div className="avatarProfile">
          <span>
            <Avatar size={65} alt="company">
              {RAZONSOCIAL[0]}
            </Avatar>
          </span>
          <div>
            <h3>{RAZONSOCIAL}</h3>
            <h5>{cuit}</h5>
          </div>
        </div>
        <li>
          <a href="/changepassword">Cambiar mi contraseña</a>
        </li>
        <Divider />
        <li onClick={() => this.props.userSignOut()}>
          <div style={{ textAlign: "center" }}>
            <span>
              <LogoutOutlined
                style={{
                  verticalAlign: "sub",
                  fontSize: "20px",
                  color: "#08c"
                }}
              />{" "}
              Cerrar sesión{" "}
            </span>
          </div>
        </li>
      </ul>
    );

    return (
      <Popover
        overlayClassName="gx-popover-horizantal"
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <div className="gx-pointer">
          <span className="headerCompanyName">{RAZONSOCIAL}</span>
          <Avatar style={{ cursor: "arrow" }} size={32} alt="company">
            {RAZONSOCIAL[0]}
          </Avatar>
        </div>
      </Popover>
    );
  }
}
const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};
export default connect(mapStateToProps, { userSignOut })(UserInfo);
