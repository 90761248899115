import React, { Component } from "react";
import { Layout /* , Menu ,message*/ } from "antd";
import { connect } from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import { store } from "../../../NextApp";
import languageData from "../languageData";
import { Query } from "react-apollo";
import UserInfo from "components/UserInfo";
import gql from "graphql-tag";
import HorizontalNav from "../HorizontalNav";
import { Link } from "react-router-dom";
import {
  switchLanguage,
  toggleCollapsedSideNav
} from "../../../appRedux/actions/Setting";

const { Header } = Layout;

// const menu = (
//   <Menu onClick={handleMenuClick}>
//     <Menu.Item key="1">Products</Menu.Item>
//     <Menu.Item key="2">Apps</Menu.Item>
//     <Menu.Item key="3">Blogs</Menu.Item>
//   </Menu>
// );

// function handleMenuClick(e) {
//   message.info('Click on menu item.');
// }
class InsideHeader extends Component {
  state = {
    searchText: ""
  };

  languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={e => this.props.switchLanguage(language)}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  updateSearchChatUser = evt => {
    this.setState({
      searchText: evt.target.value
    });
  };

  render() {
    const { company } = store.getState().auth;
    const variables = {
      slug: company
    };
    const COMPANY_QUERY = gql`
  query Company($slug: String!) {
    companies(slug: $slug) {
      headerImage
    }
  }
`;
    return (
      <Query query={COMPANY_QUERY} variables={variables}>
        {({ error, loading, data }) => {
          if (error) {
            return <h1>Error al cargar la página</h1>;
          }
          if (loading) {
            return null;
          }
          const { headerImage } = data.companies[0];

          return (
            <div className="gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal">
              <Header className="gx-header-horizontal-main">
                <div className="gx-container">
                  <div className="gx-header-horizontal-main-flex">
                    {/* <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3 6e">
                <i className="gx-icon-btn icon icon-menu"
                   onClick={() => {
                     this.props.toggleCollapsedSideNav(!navCollapsed);
                   }}
                />
              </div> */}
                    <Link
                      to="/"
                      className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo"
                    >
                      <img alt="" src={headerImage} />
                    </Link>
                    <Link
                      to="/"
                      className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo newLogo "
                    >
                      <img alt="" src={headerImage} />
                    </Link>

                    <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block">
                      <HorizontalNav />
                    </div>
                    <ul className="gx-header-notifications gx-ml-auto">
                      {/* <li className="gx-notify gx-notify-search">
                  <Popover overlayClassName="gx-popover-horizantal"
                           placement="bottomRight" content={
                    <div className="gx-d-flex"><Dropdown overlay={menu}>
                      <Button>
                        Category <Icon type="down"/>
                      </Button>
                    </Dropdown>
                      <SearchBox styleName="gx-popover-search-bar"
                                 placeholder="Search in app..."
                                 onChange={this.updateSearchChatUser.bind(this)}
                                 value={this.state.searchText}/></div>
                  } trigger="click">

                    <span className="gx-pointer gx-d-block"><i className="icon icon-search-new"/></span>

                  </Popover>
                </li> 

                <li className="gx-language">
                  <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
                           content={this.languageMenu()} trigger="click">
              <span className="gx-pointer gx-flex-row gx-align-items-center"><i
                className={`flag flag-24 flag-${locale.icon}`}/>
              </span>
                  </Popover>
                </li>*/}
                      <li className="gx-user-nav">
                        <UserInfo />
                      </li>
                    </ul>
                  </div>
                </div>
              </Header>
            </div>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale, navCollapsed } = settings;
  return { locale, navCollapsed };
};
export default connect(mapStateToProps, {
  toggleCollapsedSideNav,
  switchLanguage
})(InsideHeader);
