import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  COMPANY_DATA
} from "../../constants/ActionTypes";

export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = ({ email, password, name }) => {
  // TODO DEFINIR EL SIGNUP COMO SERá
  /* 
  console.log(email, password);
  return (dispatch) => {
    dispatch({type: FETCH_START});
    
    axios.post('auth/register', {
        email: email,
        password: password,
        name: name
      }
    ).then(({data}) => {
      console.log("data:", data);
      if (data.result) {
        localStorage.setItem("token", JSON.stringify(data.token.access_token));
        axios.defaults.headers.common['access-token'] = "Bearer " + data.token.access_token;
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
        dispatch({type: USER_DATA, payload: data.user});
      } else {
        console.log("payload: data.error", data.error);
        dispatch({type: FETCH_ERROR, payload: "Network Error"});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  } */
};

export const userSignIn = ({ token, user, cuit, company, dataCompanies }, error) => {
  let newUser = { ...user, cuit, admin_email: dataCompanies?.admin_email };

  return dispatch => {
    dispatch({ type: FETCH_START });
    if (!error) {
      localStorage.setItem("token", JSON.stringify(token));
      localStorage.setItem("user", JSON.stringify(newUser));
      localStorage.setItem("company", JSON.stringify(company));
      localStorage.setItem("admin_email", JSON.stringify(dataCompanies?.admin_email));
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: COMPANY_DATA, payload: company });
      dispatch({ type: USER_DATA, payload: newUser });
      dispatch({ type: USER_TOKEN_SET, payload: token });
    } else {
      dispatch({ type: FETCH_ERROR, payload: error });
    }
  };
};

export const getUser = () => {
  //TODO: implementaremos este endpoint o lo bocho?
  /* return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/me',
    ).then(({data}) => {
      console.log("userSignIn: ", data);
      if (data.result) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_DATA, payload: data.user});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  } */
};

export const userSignOut = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: SIGNOUT_USER_SUCCESS });
  };
};
